import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import classes from "./Images.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { modelActions } from "../../store/model";
import {
  getCollection,
  getCollectionPreviews,
  getCollections,
  imagesActions,
} from "../../store/images";
import usePageEnd from "../../hooks/use-page-end";
import { useOnlineStatus } from "../../hooks/use-online-status";
import ErrorMessage from "../ui/ErrorMessage";
import Spinner from "../ui/Spinner";
import { ERROR_MESSAGE_OFFLINE } from "../../variables/constants";
import CollectionPreview from "../collection/collection-preview/CollectionPreview";
import CollectionList from "../collection/collection-list/CollectionList";
import CategoryList from "../ui/lists/CategoryList";
import ButtonCategoryAll from "../ui/buttons/ButtonCategoryAll";
import SubcategoryList from "../ui/lists/SubcategoryList";
import CategoryListItem from "../ui/lists/CategoryListItem";
import Modal from "../ui/Modal";
import { AnimatePresence } from "framer-motion";
import CategoriesForm from "../forms/categories-form/CategoriesForm";
import { sortArrayBy } from "../../utils/generalUtils";
import NotificationMessage from "../ui/NotificationMessage";

const Images = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [isSubcategory, setIsSubcategory] = useState(false);
  const isAuth = useSelector((state) => state.auth.user.uid);
  const categories = useSelector((state) => state.images.categories);
  const collectionPreviews = useSelector(
    (state) => state.images.collectionPreviews
  );
  const isLastPage = useSelector((state) => state.images.isLastPreviewsPage);
  const isLoading = useSelector((state) => state.images.previewsIsLoading);
  const errorMessage = useSelector(
    (state) => state.images.previewsErrorMessage
  );
  const activeCategory = useSelector((state) => state.images.activeCategory);
  const activeSubcategory = useSelector(
    (state) => state.images.activeSubcategory
  );
  const nsfwMode = useSelector((state) => state.model.nsfwMode);
  const endPage = useRef(null);
  const isPageEnd = usePageEnd(100);
  const isOnline = useOnlineStatus();
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const subcategories = categories?.find(
    (category) => category.id === activeCategory
  )?.subcategories;

  useEffect(() => {
    setIsIntersecting(isPageEnd);
  }, [isPageEnd]);

  const openCategoryHandler = (e) => {
    dispatch(imagesActions.setActiveCategory(e.target.dataset.value));
    dispatch(imagesActions.setActiveSubcategory(""));
    dispatch(imagesActions.setCollectionPreviews([]));
    dispatch(imagesActions.resetCollectionPreviews());
  };

  const openSubcategoryHandler = (e) => {
    dispatch(imagesActions.setActiveSubcategory(e.target.dataset.value));
    dispatch(imagesActions.setCollectionPreviews([]));
    dispatch(imagesActions.resetCollectionPreviews());
  };

  const categoriesHtml = sortArrayBy(categories, "name")?.map((category) => {
    return (
      <CategoryListItem
        key={category.id}
        onClick={openCategoryHandler}
        dataValue={category.id}
        active={category.id === activeCategory}
        // className={`${
        //   category.id === activeCategory ? classes["category--active"] : ""
        // }`}
      >
        {category.name}
      </CategoryListItem>
      // <li
      //   key={category.id}
      //   data-value={category.id}
      //   onClick={openCategoryHandler}
      //   className={`${classes["category"]} ${
      //     category.id === activeCategory ? classes["category--active"] : ""
      //   }`}
      // >
      //   {category.name}
      // </li>
    );
  });

  const subcategoriesHtml = sortArrayBy(subcategories, "name")?.map(
    (subcategory, i) => {
      return (
        <CategoryListItem
          key={subcategory.id}
          onClick={openSubcategoryHandler}
          dataValue={subcategory.id}
          active={subcategory.id === activeSubcategory}
          className={`${classes["subcategory"]} ${
            subcategory.id === activeSubcategory
              ? classes["subcategory--active"]
              : ""
          } ${classes["subcategory--border"]}`}
        >
          {subcategory.name}
        </CategoryListItem>
        // <li
        //   key={subcategory.id}
        //   data-value={subcategory.id}
        //   onClick={openSubcategoryHandler}
        //   className={`${classes["subcategory"]} ${
        //     subcategory.id === activeSubcategory
        //       ? classes["subcategory--active"]
        //       : ""
        //   }`}
        // >
        //   {subcategory.name}
        // </li>
      );
    }
  );

  //Load initial previews
  useEffect(() => {
    // console.log("USEEFECT");
    const rule =
      activeSubcategory || activeCategory === "all" || !subcategories?.length;
    // console.log(isLastPage);
    // console.log(activeSubcategory)
    // console.log(activeCategory)
    // console.log(subcategories?.length)
    // console.log(collectionPreviews);
    if (
      !collectionPreviews?.data?.length &&
      !isLastPage &&
      isOnline &&
      rule &&
      activeCategory
    ) {
      dispatch(
        getCollectionPreviews(
          activeCategory,
          activeSubcategory,
          false,
          nsfwMode
        )
      );
    }
  }, [
    dispatch,
    collectionPreviews,
    nsfwMode,
    isLastPage,
    isOnline,
    activeCategory,
    activeSubcategory,
    subcategories,
  ]);

  //Load previews on scroll
  useEffect(() => {
    if (
      !isLastPage &&
      isIntersecting &&
      !!collectionPreviews?.data?.length &&
      isOnline &&
      !isLoading
    ) {
      clearTimeout(timeoutRef.current);
      setIsIntersecting(false);
      timeoutRef.current = setTimeout(() => {
        dispatch(
          getCollectionPreviews(
            activeCategory,
            activeSubcategory,
            true,
            nsfwMode
          )
        );
      }, 1000);
    }
  }, [
    isIntersecting,
    dispatch,
    isLastPage,
    collectionPreviews,
    nsfwMode,
    isOnline,
    activeCategory,
    activeSubcategory,
    isLoading,
  ]);

  // const collectionsHtml = collectionPreviews?.data?.map((collection) => {
  //   return (
  //     // <li

  //     //   data-value={collection.id}
  //     //   // onClick={openSubcategoryHandler}
  //     //   className={`${classes["collection"]} `}
  //     // >
  //     //   <Link to={`/images/${collection.id}`} onClick={openSubcategoryHandler}>
  //     //     {collection.name}
  //     //   </Link>
  //     // </li>
  //     <CollectionPreview key={collection.id} previewData={collection} />
  //   );
  // });

  const editCategoriesHandler = (isSub) => {
    // console.log(categories);
    // console.log(subcategories);
    setIsSubcategory(isSub);
    setEditIsOpen(true);
  };

  return (
    <div>
      <div className={classes["categories-container"]}>
        {!!categories?.length && (
          <CategoryList onEdit={editCategoriesHandler.bind(null, false)}>
            <ButtonCategoryAll
              onClick={openCategoryHandler}
              className={`${activeCategory === "all" ? classes.active : ""}`}
              activeCategory={activeCategory}
            />
            {categoriesHtml}
          </CategoryList>
        )}
        {!categories?.length && (
          <NotificationMessage className={classes.notification}>
            <p className={classes["tip__content__text"]}>
              To create a new collection, open the side panel using the button
              on the right and click "New resource". Fill in the requered fields
              and click "Save".
            </p>
          </NotificationMessage>
        )}
        {!!activeCategory &&
          activeCategory !== "all" &&
          !!subcategories?.length && (
            <SubcategoryList onEdit={editCategoriesHandler.bind(null, true)}>
              <ButtonCategoryAll
                onClick={openSubcategoryHandler}
                className={`${
                  activeSubcategory === "all" ? classes.active : ""
                }`}
                activeCategory={activeSubcategory}
              />
              {subcategoriesHtml}
            </SubcategoryList>
          )}
      </div>
      {activeCategory && (activeSubcategory || !subcategories?.length) && (
        <CollectionList />
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!isOnline && <ErrorMessage>{ERROR_MESSAGE_OFFLINE}</ErrorMessage>}
      <div ref={endPage}></div>
      {isLoading && (
        <div className={classes["spiner-container"]}>
          <Spinner size="medium" />
        </div>
      )}
      <AnimatePresence>
        {editIsOpen && (
          <Modal
            title="Subcategories"
            onClose={() => {
              setEditIsOpen(false);
            }}
          >
            <CategoriesForm
              modelType="collections"
              activeCategory={isSubcategory ? activeCategory : null}
              categories={categories}
            />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Images;
